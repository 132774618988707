import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css'; // Optional: If you want to add global styles
import App from './App'; // Import the App component
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router from React Router

// Create the root and render the App component wrapped with BrowserRouter
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>
);
