import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <Link to="/" className="logo">MyLogo</Link>
      <div className={`menu-toggle ${menuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
        &#9776; {/* Hamburger Icon */}
      </div>
      <nav className={menuOpen ? 'active' : ''}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/About">About</Link></li>
          <li><Link to="/Contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
