import React from 'react';
import { Route, Routes } from 'react-router-dom'; // For routing
import Header from './Header'; // Your header component
import Home from './Home';
import About from './About';
import Contact from './Contact';

const App = () => {
  return (
    <div>
      <Header /> {/* This will render your header with links */}
      <main>
        <Routes> {/* Define your routes here */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;
